import GenericField from "@components/formFields/GenericField";
import { Field, useField } from "formik";
import { useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import styles from "./selectInput.module.scss";

interface SelectInputProps {
  className?: string;
  isDisabled?: boolean;
  key?: string;
  label?: string;
  name: string;
  options: { label: string; value: string }[];
  placeholder?: string;
  type?: string;
  roundBorders?: boolean;
  disabled?: boolean;
}

export default function SelectInput({
  className,
  name,
  label,
  roundBorders,
  ...props
}: SelectInputProps): JSX.Element {
  const [field] = useField(name);

  // TODO Add click event to arrow
  const selectInputReference = useRef(null);

  const dispatchClick = (ref: any) => {
    if (ref?.current) {
      let event = document.createEvent("MouseEvents");
      //@ts-ignore
      event.initMouseEvent("mousedown", true, true, window);
      ref.current.dispatchEvent(event);
    }
  };

  return (
    <GenericField name={name} label={label}>
      <div className={styles.rangeInput}>
        <Field
          className={roundBorders ? styles.round : undefined}
          as="select"
          data-testid="selectInput"
          {...field}
          {...props}
          name={name}
          innerRef={selectInputReference}
          id="selectInputReference"
        >
          {props.options.map(
            (option: { label: string; value: string }, index: number) => (
              <option
                key={index}
                value={option.value}
                defaultValue={field.value}
              >
                {option.label}
              </option>
            )
          )}
        </Field>
        <IoIosArrowDown onClick={() => dispatchClick(selectInputReference)} />
      </div>
    </GenericField>
  );
}
