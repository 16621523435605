import React, { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./fadeInSection.module.scss";

interface FadeInSectionProps {
  children: ReactNode;
}

const FadeInSection: React.FC<FadeInSectionProps> = ({ children }) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) setVisible(true);
      });
    });
    let current = domRef.current;
    if (current != null) {
      observer.observe(current);
      return () => observer.unobserve(current!);
    }
  }, []);

  return (
    <div
      className={`${styles.referrer} ${styles.fadeInSection} ${
        isVisible && styles.isVisible
      }`}
      ref={domRef}
    >
      {children}
    </div>
  );
};

export default FadeInSection;
