import React, { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { AnimatePresence, motion } from "framer-motion";
import { CgMenuLeftAlt } from "react-icons/cg";
import Container from "@components/Container";
import Button from "@components/buttons/Button";
import { scrollIntoView } from "@lib/constants";
import ButtonLink from "@components/buttons/ButtonLink";
import styles from "./publicHeader.module.scss";

interface PublicHeaderReferenceProps {
  simulationRef?: object;
  componentUrl?: string;
  selectedTab?: string;
}

const PublicHeader: React.FC<PublicHeaderReferenceProps> = ({
  simulationRef,
  componentUrl = "/",
}) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <header className={styles.header}>
      <Container className={styles.content}>
        <CgMenuLeftAlt
          className={styles.burgerMenu}
          onClick={() => setToggleMenu(!toggleMenu)}
          size={25}
        />
        <div className={styles.logo}>
          <Link href="/">
            <a>
              <Image
                src="/icon-cloud.svg"
                alt="Segurize Icon"
                width="100%"
                height="100%"
              />
            </a>
          </Link>
        </div>

        <nav className={styles.links} data-testid="publicHeader">
          <Link href="/about">
            <a>Sobre</a>
          </Link>

          <Link href="/faq">
            <a>Dúvidas</a>
          </Link>

          <Link href="/login">
            <a>
              <Button themeColor="flatTextPrimary" type="button">
                Entrar
              </Button>
            </a>
          </Link>
        </nav>
        <div className={styles.mainBtn}>
          {simulationRef ? (
            <Button
              themeColor="primaryOutline"
              type="button"
              onClick={() => scrollIntoView(simulationRef)}
            >
              Simular agora
            </Button>
          ) : (
            <ButtonLink
              themeColor="primaryOutline"
              to={componentUrl ? (componentUrl as string) : "/#simulation"}
            >
              Simular agora
            </ButtonLink>
          )}
        </div>

        <AnimatePresence>
          {toggleMenu && (
            <motion.div
              className={styles.mobileNavigation}
              initial={{ top: -100, opacity: 0 }}
              animate={{ top: "var(--size-xl)", opacity: 1 }}
              exit={{ top: -60, opacity: 0 }}
              transition={{
                delay: 0.1,
                ease: [0.075, 0.82, 0.165, 1],
              }}
            >
              <nav className={styles.mobileLinks} data-testid="publicHeader">
                <Link href="/about">
                  <a>Sobre</a>
                </Link>

                <Link href="/faq">
                  <a>Dúvidas</a>
                </Link>

                <ButtonLink themeColor="flatTextPrimary" to="/login">
                  Entrar
                </ButtonLink>
              </nav>
              <div className={styles.mobileMainBtn}>
                {simulationRef ? (
                  <Button
                    themeColor="primaryOutline"
                    type="button"
                    onClick={() => scrollIntoView(simulationRef)}
                  >
                    Simular agora
                  </Button>
                ) : (
                  <ButtonLink
                    themeColor="primaryOutline"
                    to={componentUrl as string}
                  >
                    Simular agora
                  </ButtonLink>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </Container>
    </header>
  );
};

export default PublicHeader;
