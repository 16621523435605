import Collapsible from "@components/animations/Collapsible";
import AssistanceCard from "@components/AssistanceCard";
import Button from "@components/buttons/Button";
import { beginCheckoutEvent } from "@lib/gtm";
import { toMoney } from "@lib/stringFormatters";
import { useSubscription } from "@lib/subscriptionContext";
import { Benefit } from "@lib/hooks/usePlan";
import { useReferrer } from "@lib/hooks/useReferrer";
import { motion } from "framer-motion";
import React, { useState } from "react";
import styles from "./simulationCard.module.scss";
import SlideText from "@components/animations/SlideText";

interface SimulationCardProps {
  name: string;
  price: number;
  benefits: Benefit[];
  planId: number;
  planConfigId: number;
}

const SimulationCard: React.FC<SimulationCardProps> = ({
  name,
  price,
  benefits,
  planId,
  planConfigId,
}) => {
  const referrer = useReferrer();
  const { startSubscription } = useSubscription();

  const [isOpen, setIsOpen] = useState(false);

  const beginCheckout = (click_location: string) => {
    beginCheckoutEvent(
      referrer?.code,
      "real",
      [
        {
          plan_id: planId,
          plan_config_id: planId,
          product: "segurize_life",
          click_location: click_location,
        },
      ],
      price
    );
    startSubscription(planId, planConfigId);
  };

  const dailyHosp = benefits.find((c) => c.slug == "daily-hospitalization");

  const death = benefits.find((c) => c.slug == "death");
  const accidentalDeath = benefits.find((c) => c.slug == "accidental-death");
  const individualTelemedicine = benefits.find(
    (c) => c.slug == "individual-telemedicine"
  );
  const familyTelemedicine = benefits.find(
    (c) => c.slug == "family-telemedicine"
  );
  const tempIncapacity = benefits.find((c) => c.slug == "temporary-disability");
  const breastCancer = benefits.find((c) => c.slug == "breast-cancer");
  const unemployment = benefits.find((c) => c.slug == "income-loss");

  const isIncomeLossAdaptation = (benefit: Benefit) => {
    return benefit == unemployment
      ? benefit.coverage_value / 3
      : benefit.coverage_value;
  };

  return (
    <motion.div className={styles.container}>
      <div className={styles.title}>
        <h3>{name}</h3>

        <h1>
          <p>R$</p>
          <SlideText direction="down" text={toMoney(price)} />
          <p>/mês</p>
        </h1>
      </div>

      <hr />
      <div className={styles.advantages}>
        <h6>Principais vantagens:</h6>
        <ul>
          {dailyHosp && (
            <li>
              Receba até R$ {toMoney(dailyHosp.coverage_value * 30)} por mês em
              caso de hospitalização comprovada
            </li>
          )}
          {tempIncapacity && (
            <li>
              Receba até R$ {toMoney(tempIncapacity.coverage_value * 30)} por
              mês em caso de hospitalização comprovada
            </li>
          )}

          {unemployment && (
            <li>
              Receba R$ {toMoney(unemployment.coverage_value / 3)} em caso de
              desemprego por até 3 meses
            </li>
          )}

          {(death || accidentalDeath) && (
            <li>
              Até R${" "}
              {((death?.coverage_value || 0) +
                (accidentalDeath?.coverage_value || 0)) /
                1000}{" "}
              mil de indenização para quem você ama caso você venha a faltar
            </li>
          )}

          {individualTelemedicine && (
            <li>
              Telemedicina: Consultas médicas online quando necessário para você
            </li>
          )}

          {familyTelemedicine && (
            <li>
              Telemedicina: Consultas médicas online quando necessário para você
              e sua família
            </li>
          )}

          {breastCancer && (
            <li>
              Indenização de {breastCancer.coverage_value / 1000} mil em caso de
              diagnostico de câncer de mama ou ginecológico
            </li>
          )}

          {tempIncapacity && (
            <li>
              Receba R$ 1.200,00 por mês em caso de afastamento médico por
              atestado por até 1 ano
            </li>
          )}
        </ul>
      </div>

      <hr />

      <Button
        type="button"
        themeColor="primary"
        onClick={() => {
          beginCheckout("simulation");
        }}
      >
        Quero esse
      </Button>
      <hr />

      <div className={styles.benefits} onClick={() => setIsOpen(!isOpen)}>
        <h6>
          Lista completa dos {benefits.length} benefícios&nbsp;
          {isOpen ? <span>-</span> : <span>+</span>}
        </h6>

        <div>
          <Collapsible open={isOpen}>
            <div className={styles.productDetails}>
              <ul>
                {benefits.map((benefit, index) => {
                  return (
                    <li key={index}>
                      {benefit.coverage_value ? (
                        <i>R$ {toMoney(isIncomeLossAdaptation(benefit))} - </i>
                      ) : (
                        ""
                      )}{" "}
                      {benefit.description}
                    </li>
                  );
                })}
              </ul>
            </div>
          </Collapsible>
        </div>
      </div>
    </motion.div>
  );
};

export default SimulationCard;
