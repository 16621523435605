import { useQuery } from "react-query";
import { publicClient } from "../api";
import { AVAILABLE_PLANS_URL } from "../urls";

const getAvailablePlans = () => async () => {
  const response = await publicClient.get(AVAILABLE_PLANS_URL);
  return response.data.map((p: any) => ({
    ...p,
    assistances: p.assistances.map((a: any) => ({
      ...a,
      coverage_value: parseFloat(a.coverage_value),
    })),
    coverages: p.coverages.map((c: any) => ({
      ...c,
      coverage_value: parseFloat(c.coverage_value),
    })),
  }));
};

export function useAvailablePlans() {
  return useQuery(AVAILABLE_PLANS_URL, getAvailablePlans(), {
    retry: 2,
  });
}
