import PressedAnimation from "@components/animations/PressedAnimation";
import React, { Fragment, ReactNode } from "react";
import LoadingDots from "../../LoadingDots";
import styles from "../button.module.scss";

interface ButtonProps {
  testId?: string;
  themeColor:
    | "primary"
    | "primaryOutline"
    | "secondary"
    | "secondaryOutline"
    | "whiteSecondary"
    | "outlineGrey"
    | "google"
    | "apple"
    | "facebook"
    | "flatTextPrimary"
    | "flatTextSecondary"
    | "flatTextDanger";
  icon?: string;
  type: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
  children: ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  testId = "button",
  type,
  onClick,
  disabled,
  children,
  themeColor,
  isLoading,
}) => {
  return (
    <PressedAnimation>
      <button
        className={`${styles.button} ${styles[themeColor]}`}
        type={type}
        disabled={disabled}
        onClick={onClick}
        data-testid={testId}
      >
        {isLoading ? <LoadingDots /> : <Fragment>{children}</Fragment>}
      </button>
    </PressedAnimation>
  );
};
export default Button;
