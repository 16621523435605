import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode } from "react";

const variants = {
  hidden: { height: 0, opacity: 0, overflow: "hidden" },
  enter: { height: "inherit", opacity: 1 },
};

interface CollapsibleProps {
  open: boolean;
  className?: string;
  children: ReactNode;
}

const Collapsible: React.FC<CollapsibleProps> = ({
  open,
  children,
  ...props
}) => {
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          variants={variants}
          initial="hidden"
          animate="enter"
          exit="hidden"
          transition={{
            type: "spring",
            stiffness: 250,
            damping: 30,
            duration: 0.4,
          }}
          {...props}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Collapsible;
