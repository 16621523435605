import { ErrorMessage, Field, useField } from "formik";
import styles from "./genericField.module.scss";

interface GenericFieldProps {
  children?: React.ReactNode;
  className?: string;
  testId?: string;
  componentType?: string;
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
  name: string;
  type?: string;
}

const GenericField: React.FC<GenericFieldProps> = ({
  children,
  label,
  componentType,
  name,
  ...props
}) => {
  const [field, meta] = useField(name);

  return (
    <div className={styles.container} data-testid={label}>
      {label && <label htmlFor={name}>{label}</label>}
      <div
        style={{ width: "100%" }}
        className={meta.touched && meta.error ? styles.inputError : undefined}
      >
        {children ? (
          children
        ) : (
          <Field as={componentType} {...field} {...props} />
        )}
      </div>
      <div className={styles.errorContainer}>
        <ErrorMessage className={styles.errorMsg} component="div" name={name} />
      </div>
    </div>
  );
};

export default GenericField;
