import React from "react";
import Link from "next/link";
import Image from "next/image";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import Container from "../Container";
import styles from "./footer.module.scss";
import segurizeWhite from "@public/segurize-white.svg";

function Footer() {
  return (
    <div className={styles.footerContainer}>
      <Container>
        <div className={styles.footerContent}>
          <div className={styles.footerLogo}>
            <span className={styles.logo}>
              <Link href="/">
                <a>
                  <Image
                    src={segurizeWhite}
                    alt="Logo da Segurize"
                    width={80}
                    height={68}
                  />
                </a>
              </Link>
            </span>
          </div>

          <div className={styles.footerLinks}>
            <Link href="/faq">
              <a target="_blank">Dúvidas</a>
            </Link>
            <Link href="/">
              <a target="_blank">Vida Viva Bradesco</a>
            </Link>
            <Link href="/terms">
              <a target="_blank">Termos de uso</a>
            </Link>
            <Link href="/about/">
              <a target="_blank">Quem somos</a>
            </Link>
            <Link href="/privacy-policy/">
              <a target="_blank">Privacidade</a>
            </Link>
          </div>

          <div className={styles.contactUs} data-testid="contactUs">
            <div>
              <div className={styles.contact}>
                <span>
                  <b>Fale com a gente:</b>
                  <br />+ 55 (11) 3284-5871 <br />
                  meajuda@segurize.com.br
                  <br />
                  Av.Paulista nº 91 cj: 804 CEP:01311-000
                </span>
              </div>

              <div className={styles.socialLinks}>
                <div className={styles.socialLink}>
                  <a
                    data-testid="instagram"
                    href="https://www.instagram.com/segurize/"
                    target="_blank"
                    rel="noreferrer"
                    title="Instagram Segurize"
                  >
                    <FaInstagram />
                  </a>
                </div>

                <div className={styles.socialLink}>
                  <a
                    data-testid="facebook"
                    href="https://www.facebook.com/Segurize"
                    target="_blank"
                    rel="noreferrer"
                    title="Facebook Segurize"
                  >
                    <FaFacebook />
                  </a>
                </div>

                <div className={styles.socialLink}>
                  <a
                    data-testid="youtube"
                    href="https://www.youtube.com/channel/UCtdqIVSuZBQK7mwOknGezoA"
                    target="_blank"
                    rel="noreferrer"
                    title="Youtube Seguize"
                  >
                    <FaYoutube />
                  </a>
                </div>

                <div className={styles.socialLink}>
                  <a
                    data-testid="linkedin"
                    href="https://www.linkedin.com/company/segurize"
                    target="_blank"
                    rel="noreferrer"
                    title="Linkedin Segurize"
                  >
                    <FaLinkedin />
                  </a>
                </div>
              </div>
            </div>

            <p className={styles.copyrightMessage}>
              Feito com amor pelo time Segurize. Todos os direitos reservados.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
