import Button from "@components/buttons/Button";
import { ErrorMessage, useField } from "formik";
import React, { ReactElement } from "react";
import styles from "./selectInput.module.scss";

interface SelectOneButtonFieldProps {
  className?: string;
  label?: string;
  name: string;
  activeColor?: "primary" | "primaryOutline" | "secondary" | "secondaryOutline";
  options: {
    label: string;
    value: string;
    icon?: ReactElement;
  }[];
}

export default function SelectOneButtonField({
  className,
  label,
  name,
  options,
  activeColor = "primary",
  ...props
}: SelectOneButtonFieldProps): JSX.Element {
  const [field, meta, helpers] = useField(name);

  const handleClick = (value: string) => {
    helpers.setValue(value);
  };

  return (
    <div className={`${styles.field} ${className ?? ""}`} {...props}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={styles.buttonRow}>
        {options.map((option) => (
          <Button
            key={option.value}
            type="button"
            themeColor={
              option.value == field.value ? activeColor : "outlineGrey"
            }
            onClick={() => handleClick(option.value)}
          >
            {option.icon}
            {option.label}
          </Button>
        ))}
      </div>
      <div className={styles.errorContainer}>
        <ErrorMessage component="div" className="inputErrorMsg" name={name} />
      </div>
    </div>
  );
}
