import Button from "@components/buttons/Button";
import SimulationCard from "@components/cards/SimulationCard";
import Container from "@components/Container";
import RangeInput from "@components/formFields/RangeInput";
import SelectInput from "@components/formFields/SelectInput";
import SelectOneButtonField from "@components/formFields/SelectOneButtonField";
import LoadingDots from "@components/LoadingDots";
import Switcher from "@components/Switcher";
import { occupationList } from "@lib/constants";
import { useAvailablePlans } from "@lib/hooks/useAvailablePlans";
import { Plan } from "@lib/hooks/usePlan";
import { Form, Formik } from "formik";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FaMars, FaVenus } from "react-icons/fa";
import { toast } from "react-toastify";
import styles from "./planSimulation.module.scss";

const SEX_CHOICES = [
  { label: "Feminino", value: "F", icon: <FaVenus /> },
  { label: "Masculino", value: "M", icon: <FaMars /> },
];

interface FormValues {
  age: number;
  occupation: string;
  sex: "F" | "M";
}

const PlanSimulation = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState<0 | 1>(0);
  const plansListRef = useRef<null | HTMLDivElement>(null);

  const [age, setAge] = useState(18);
  const [occupation, setOccupation] = useState("EM");
  const [familyPlan, setFamilyPlan] = useState(false);
  const [sex, setSex] = useState("F");
  const { data: plans, isError, isLoading } = useAvailablePlans();

  const selectedPlans: [Plan] = useMemo(() => {
    if ((!plans || isError) && currentStepIndex == 1) {
      toast.warn(
        "Erro ao recuperar os planos. Por favor tente novamente mais tarde",
        {
          toastId: "planUpdate",
        }
      );
      setCurrentStepIndex(0);
    }
    if (plans && currentStepIndex == 1) {
      toast.info("Detalhes dos planos foram atualizados", {
        toastId: "planUpdate",
      });
    }
    return plans?.filter(
      (plan: any) =>
        plan.family_plan == familyPlan &&
        plan.occupation == occupation &&
        plan.female_plan == (sex == "F")
    );
  }, [plans, familyPlan, occupation, sex, currentStepIndex, isError]);

  const handleSubmit = useCallback(
    async ({ age, occupation, sex }: FormValues) => {
      setAge(age);
      setOccupation(occupation);
      setSex(sex);
      setCurrentStepIndex(1);
      await new Promise((r) => setTimeout(r, 100));
    },
    []
  );

  const debouncedSetState = useMemo(
    () =>
      debounce(
        ({
          age,
          occupation,
          sex,
        }: {
          age: number;
          occupation: string;
          sex: string;
        }) => {
          setAge(age);
          setOccupation(occupation);
          setSex(sex);
        },
        200
      ),
    []
  );

  const handleChange = useCallback(
    (formikValues: FormValues) => debouncedSetState(formikValues),
    [debouncedSetState]
  );

  useEffect(() => {
    if (currentStepIndex == 1 && !isLoading) {
      plansListRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [isLoading, currentStepIndex]);

  const chosenPlans = (
    <>
      <AnimatePresence>
        {currentStepIndex === 1 &&
          (isLoading ? (
            <LoadingDots color />
          ) : (
            <motion.div
              className={styles.planCards}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.1 }}
              ref={plansListRef}
            >
              {selectedPlans?.map((plan: any, index: number) => {
                const planConfig = plan.configs.find((c: any) => c.age == age);
                return (
                  <SimulationCard
                    key={index}
                    name={plan.name}
                    price={parseFloat(planConfig.value)}
                    benefits={plan.coverages.concat(plan.assistances)}
                    planId={plan.id}
                    planConfigId={planConfig.id}
                  />
                );
              })}
            </motion.div>
          ))}
      </AnimatePresence>
    </>
  );

  return (
    <Formik
      initialValues={{
        age: 18,
        occupation: "EM",
        sex: "F",
      }}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        handleChange(formik.values);
        return (
          <Container style={{ flexDirection: "column" }}>
            <div className={styles.simulationContent}>
              <LayoutGroup>
                <motion.div layout>
                  <Form>
                    <div className={styles.formLine}>
                      <div className={styles.switcher}>
                        <p>Tipo de plano</p>
                        <Switcher
                          titleList={["Individual", "Familiar"]}
                          onChange={(index) => setFamilyPlan(index == 1)}
                        />
                      </div>

                      <div className={styles.field}>
                        <p>Sua idade</p>
                        <RangeInput
                          name="age"
                          rangeLabel={["18 anos", "80 anos"]}
                          min={18}
                          max={80}
                        />
                      </div>
                    </div>

                    <div className={styles.formLine}>
                      <div className={`${styles.field} ${styles.occupation}`}>
                        <p>Sua ocupação</p>
                        <SelectInput
                          name="occupation"
                          options={occupationList}
                          roundBorders
                        />
                      </div>
                      <SelectOneButtonField
                        name="sex"
                        label="Seu sexo biológico"
                        activeColor="secondaryOutline"
                        options={SEX_CHOICES}
                        className={`${styles.field} ${styles.selectSex}`}
                      />
                    </div>

                    {currentStepIndex === 0 && (
                      <div className={`${styles.formLine} ${styles.submitBtn}`}>
                        <Button
                          themeColor={"primary"}
                          type="button"
                          onClick={formik.submitForm}
                          data-testid="btn-submit"
                        >
                          Simular agora
                        </Button>
                      </div>
                    )}
                  </Form>
                </motion.div>
              </LayoutGroup>
            </div>
            {chosenPlans}
          </Container>
        );
      }}
    </Formik>
  );
};

export default PlanSimulation;
