export const formatDocument = (value: string) =>
  value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

export const formatPhone = (value: string) =>
  value.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");

export const removeSpecialChars = (value: string) =>
  value.replace(/[-&\/\\#,+()$~%.'":*?<>{}\s]/g, "");

export const capitalize = (value: string) => {
  const wordList = value.split(" ");
  for (let i = 0; i < wordList.length; i++) {
    wordList[i] = wordList[i].charAt(0).toUpperCase() + wordList[i].slice(1);
  }
  return wordList.join(" ");
};

export const toMoney = (value: number) =>
  value.toLocaleString("pt-BR", {
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const getFirstWord = (value: string) => value.split(" ")[0];
