import Button from "@components/buttons/Button";
import BenefitCard from "@components/cards/BenefitCard";
import PublicHeader from "@components/headers/PublicHeader";
import PlanSimulation from "@components/PlanSimulation";
import { scrollIntoView, SEGURIZE_LIFE_PRICE } from "@lib/constants";
import { beginCheckoutEvent } from "@lib/gtm";
import { useReferrer } from "@lib/hooks/useReferrer";
import doctorImg from "@public/doctor.svg";
import Head from "next/head";
import Image from "next/image";
import { Fragment, useRef } from "react";
import Lottie from "react-lottie-player";
import coverageBuyAnimationData from "../../public/lotties/coverageBuy.json";
import familyAnimationData from "../../public/lotties/family.json";
import ButtonLink from "../components/buttons/ButtonLink";
import CollapseBox from "../components/CollapseBox";
import Container from "../components/Container";
import CurvedFooter from "../components/CurvedFooter";
import FadeInSection from "../components/FadeInSection";
import styles from "./home.module.scss";

export default function Home() {
  const referrer = useReferrer();
  const simulationSection = useRef(null);

  const beginCheckout = (click_location: string) => {
    beginCheckoutEvent(
      referrer?.code,
      "real",
      [
        {
          plan_id: "1", // TODO send plan_id that comes from the product api - when it exists
          product: "segurize_life",
          click_location: click_location,
        },
      ],
      SEGURIZE_LIFE_PRICE
    );
  };

  return (
    <Fragment>
      <main className={styles.container} data-testid="home">
        <Head>
          <title>Vida Viva Bradesco</title>
        </Head>

        <PublicHeader simulationRef={simulationSection} />

        <section className={styles.banner}>
          <Container>
            <div className={styles.contentBanner}>
              <h1 className={`${styles.title} ${styles.fadeInOpacity}`}>
                Tenha a melhor <span>proteção de vida </span> <br />
                para você e sua família.
              </h1>

              <Button
                type="button"
                themeColor="primary"
                onClick={() => scrollIntoView(simulationSection)}
              >
                Simular agora
              </Button>
            </div>
            <div className={styles.animationBanner}>
              <Lottie
                style={{ height: "100%", width: "100%" }}
                animationData={familyAnimationData}
                title="Ilustração - Família"
                loop
                play
              />
            </div>
          </Container>
        </section>

        <section className={styles.benefits}>
          <FadeInSection>
            <Container>
              <h2 className={styles.titleSection}>Proteção de verdade</h2>
              <p className={styles.descriptionSection}>
                Uma forma barata e fácil de se proteger, com beneficios para
                cuidar de você e de quem você ama.
              </p>
            </Container>
          </FadeInSection>

          <FadeInSection>
            <Container>
              <div className={styles.benefitsCards}>
                <BenefitCard
                  imagePath="/moneybag.svg"
                  title="Auxilio financeiro nos momentos difíceis"
                  btnUrl="/benefits/financial-help/"
                >
                  Em caso de internação ou afastamento do trabalho, a gente te
                  paga um auxílio enquanto você estiver afastado de suas
                  atividades, de acordo com o plano contratado.
                </BenefitCard>

                <BenefitCard
                  imagePath="/telemedicine.svg"
                  title="Telemedicina"
                  btnUrl="/benefits/individual-telemedicine/"
                >
                  Acesso a orientação médica online, 24 horas por dia, 7 dias
                  por semana e sem precisar sair da sua casa.
                </BenefitCard>
                <BenefitCard
                  imagePath="/funeral.svg"
                  title="Suporte nos momentos tristes da sua família"
                  btnUrl="/benefits/family-funeral/"
                >
                  Em caso de morte acidental do titular ou parente de 1º grau,
                  oferecemos assistência para cobrir despesas de funeral de até
                  R$ 5 mil mediante contato prévio com a seguradora.
                </BenefitCard>

                <BenefitCard
                  imagePath="/house.svg"
                  title="Segurança para sua casa"
                  btnUrl="/benefits/home-assistance/"
                >
                  Assistência residencial para você utilizar quando surgirem
                  emergências em casa como por exemplo, o chuveiro queimar.
                </BenefitCard>

                <BenefitCard
                  imagePath="/ap.svg"
                  title="Tranquilidade em caso de invalidez"
                  btnUrl="/benefits/accidental-disability/"
                >
                  Em caso de invalidez permanente você recebe uma indenização
                  para te ajudar no momento difícil.
                </BenefitCard>

                <BenefitCard
                  imagePath="/assistances.svg"
                  title="Melhor qualidade de vida"
                  btnUrl="/benefits/psycho-nutri-assistances/"
                >
                  Tenha acesso a orientação nutricional online e orientação
                  psicológica online.
                </BenefitCard>

                <BenefitCard
                  imagePath="/holding-hands.svg"
                  title="Apoio para quem você ama"
                  btnUrl="/benefits/death/"
                >
                  Em caso de morte do titular quem você ama recebe uma
                  indelização.
                </BenefitCard>

                <BenefitCard
                  imagePath="/cancer-ribbon.svg"
                  title="Proteção para mulheres"
                  btnUrl="/benefits/cancer-diagnosis/"
                >
                  Indenização em caso de diagnóstico de câncer de mama ou câncer
                  ginecológico.
                </BenefitCard>

                <BenefitCard
                  imagePath="/giveaways.svg"
                  title="Sorteios mensais"
                  btnUrl="/benefits/lottery/"
                >
                  Participe de sorteios todos mês pela Loteria Federal.
                </BenefitCard>
              </div>
            </Container>
          </FadeInSection>
        </section>

        <section
          className={styles.simulation}
          ref={simulationSection}
          id="simulation"
        >
          <FadeInSection>
            <Container>
              <h2 className={styles.titleSection}>
                Faça sua simulação em menos de 1 minuto
              </h2>
            </Container>
            <PlanSimulation />
          </FadeInSection>
        </section>

        <section className={styles.easyHire}>
          <FadeInSection>
            <h2 className={styles.titleSection}>Fácil para contratar e usar</h2>
          </FadeInSection>
          <Container>
            <div className={styles.easyHireContent}>
              <p>
                Contrate e realize o pagamento de maneira rápida e simples pelo
                celular ou computador
              </p>

              <div className={styles.easyHireAnimation}>
                <Lottie
                  style={{ height: "auto", width: "100%" }}
                  animationData={coverageBuyAnimationData}
                  title="Ilustração - Contratação"
                  loop
                  play
                />
              </div>
              <p>
                Veja a apólice e acione suas coberturas e assistências pelo
                celular a qualquer hora
              </p>
            </div>
          </Container>
        </section>

        <section className={styles.digital}>
          <FadeInSection>
            <h2 className={styles.titleSection}>100% digital</h2>
          </FadeInSection>
          <Container>
            <div className={styles.digitalContent}>
              <div className={styles.digitalContentItem}>
                <FadeInSection>
                  <Image
                    src="/fi_check-circle.svg"
                    alt="Vida Viva Bradesco"
                    width="60px"
                    height="60px"
                  />
                  <h6>Pagamento por assinatura</h6>
                  <p>
                    Pagamento mensal, direto do seu cartão de crédito mas sem
                    comprometer o limite.
                  </p>
                </FadeInSection>
              </div>
              <div className={styles.digitalContentItem}>
                <FadeInSection>
                  <Image
                    src="/fi_check-circle.svg"
                    alt="Vida Viva Bradesco"
                    width="60px"
                    height="60px"
                  />
                  <h6>Tudo na palma de suas mãos</h6>
                  <p>
                    Com o aplicativo você tem acesso a todos os benefícios de
                    sua apólice.
                  </p>
                </FadeInSection>
              </div>
              <div className={styles.digitalContentItem}>
                <FadeInSection>
                  <Image
                    src="/fi_check-circle.svg"
                    alt="Vida Viva Bradesco"
                    width="60px"
                    height="60px"
                  />
                  <h6>Atendimento personalizado</h6>
                  <p>
                    Se aparecer qualquer dúvida ou dificuldade basta entrar em
                    contato com nosso time.
                  </p>
                </FadeInSection>
              </div>
            </div>
          </Container>
        </section>

        <section className={styles.prize}>
          <FadeInSection>
            <div className={styles.gift}>
              <Image
                src="/gift.svg"
                alt="Presente"
                width="300px"
                height="100%"
              />
            </div>
            <Container>
              <div className={styles.prizeContent}>
                <h3>
                  Além da proteção: contrate e concorra a sorteios de R$75 mil
                  todos os meses!
                </h3>
              </div>
            </Container>
          </FadeInSection>
        </section>
        <section className={styles.questions}>
          <Container>
            <div className={styles.questionsContent}>
              <div className={styles.questionsDescription}>
                <h2 className={styles.titleSection}>Dúvidas</h2>

                <p className={styles.descriptionSection}>
                  Conheça ainda mais e tire suas dúvidas sobre nós.
                </p>
              </div>
              <div className={styles.questionsBody}>
                <CollapseBox title="Quem é a Segurize?">
                  <p data-testid="answerCollapseBox">
                    A Segurize é uma startup do mercado de seguros (insurtech)
                    que oferece produtos e serviços totalmente online
                    possibilitando um processo de contratação e utilização
                    simples, seguro e sem burocracia. <br />
                    Qualquer pessoa pode se cadastrar no site e contratar nossos
                    produtos, após a contratação é possível indicar os produtos
                    Segurize para amigos e parentes. E a cada indicação
                    convertida em venda, quem indicou ganha pontos que podem ser
                    trocados por prêmios.
                  </p>
                </CollapseBox>
                <CollapseBox title="Quem são os parceiros da Segurize">
                  <p>
                    A Segurize tem alguns parceiros para oferecer os melhores
                    benefícios aos clientes.
                    <br />
                    Nosso atual parceiro para a oferta do produto Vida Viva
                    Bradesco é a Bradesco Vida e Previdência, que garante
                    proteção para a vida de nossos clientes.
                  </p>
                </CollapseBox>
                <CollapseBox title="Como eu contrato o Vida Viva Bradesco?">
                  <p>
                    Para contratar basta entrar em nosso site
                    www.segurize.com.br e seguir o fluxo de contratação e
                    pagamento.
                  </p>
                </CollapseBox>
                <ButtonLink to="/faq" themeColor="secondaryOutline">
                  <p>Tem outra dúvida que não está aqui?</p>
                </ButtonLink>
              </div>
            </div>
          </Container>
        </section>
      </main>

      <div className={styles.doctorImage}>
        <FadeInSection>
          <Image src={doctorImg} alt="Médica da Segurize" height="400px" />
        </FadeInSection>
      </div>

      <CurvedFooter>
        <div className={styles.footerCustomization}>
          <h2>É simples. É fácil. É Segurize.</h2>
          <div className={styles.buttonContainer}>
            <Button
              type="button"
              themeColor="whiteSecondary"
              onClick={() => scrollIntoView(simulationSection)}
            >
              Contratar
            </Button>
          </div>
        </div>
      </CurvedFooter>
    </Fragment>
  );
}
