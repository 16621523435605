import { motion } from "framer-motion";
import React from "react";

interface SlideUpProps {
  text: string;
  direction: "up" | "down";
  animateOnStart?: boolean;
}

const SlideText: React.FC<SlideUpProps> = ({
  text,
  direction,
  animateOnStart = true,
}) => {
  const variants = {
    hidden: { opacity: 0, y: direction === "up" ? 50 : -50, x: 0 },
    enter: { opacity: 1, y: 0, x: 0 },
    exit: { opacity: 0, y: direction === "down" ? -50 : 50, x: 0 },
  };

  return (
    <motion.div
      key={text}
      variants={variants}
      initial="hidden"
      whileInView="enter"
      viewport={{ once: true }}
    >
      {text}
    </motion.div>
  );
};

export default SlideText;
