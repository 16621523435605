import { HTMLAttributes } from "react";
import styles from "./container.module.scss";

/**
 * Responsive container
 */
const Container: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className = "",
  ...props
}) => (
  <div className={`${styles.container} ${className}`} {...props}>
    {children}
  </div>
);

export default Container;
