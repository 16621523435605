import React, { useState } from "react";
import { AnimateSharedLayout, motion } from "framer-motion";
import { secondaryColorPure } from "@styles/globals";
import styles from "./switcher.module.scss";

interface SwitcherProps {
  children?: React.ReactNode[];
  titleList: string[];
  initialStep?: number;
  onChange?: (step: number) => void;
}

const spring = {
  type: "spring",
  stiffness: 350,
  damping: 35,
};

const Switcher: React.FC<SwitcherProps> = ({
  children,
  titleList,
  initialStep,
  onChange,
}) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(initialStep || 0);

  const cssVariables = {
    "--pages-length": titleList.length,
  } as React.CSSProperties;

  const onClickTitle = (index: number) => {
    setCurrentPageIndex(index);
    if (onChange) onChange(index);
  };

  return (
    <div className={styles.container} style={cssVariables}>
      <div className={styles.switcher}>
        {titleList.map((title, index) => {
          const isSelected = index === currentPageIndex;

          return (
            <div key={index} onClick={() => onClickTitle(index)}>
              <p className={isSelected ? styles.selectedText : ""}>{title}</p>
              {isSelected && (
                <motion.div
                  key={secondaryColorPure}
                  className={styles.selected}
                  layoutId={styles.selected}
                  initial={false}
                  animate={{ backgroundColor: secondaryColorPure }}
                  transition={spring}
                  data-testid={"switcherTab" + index}
                />
              )}
            </div>
          );
        })}
      </div>
      {children && (
        <div className={styles.content}>{children[currentPageIndex]}</div>
      )}
    </div>
  );
};

export default Switcher;
