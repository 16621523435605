import React from "react";
import { motion } from "framer-motion";
import Image from "next/image";
import styles from "./benefitCard.module.scss";

interface BenefitCardProps {
  imagePath: string;
  title: string;
  children: React.ReactNode;
  btnUrl: string;
}

const BenefitCard: React.FC<BenefitCardProps> = ({
  imagePath,
  title,
  children,
  btnUrl,
}) => {
  return (
    <motion.div className={styles.card} whileHover={{ scale: 1.1 }}>
      <div className={styles.imgContainer}>
        <Image
          src={imagePath}
          alt="Vida Viva Bradesco"
          layout="fill"
          objectFit="contain"
          data-testid="cardImage"
        />
      </div>
      <div className={styles.cardContent}>
        <h2>{title}</h2>
        <p>{children}</p>
        <a href={btnUrl} className={styles.knowMore}>
          Conhecer mais
        </a>
      </div>
    </motion.div>
  );
};

export default BenefitCard;
