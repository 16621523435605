import React, { ReactNode } from "react";
import { motion } from "framer-motion";

interface PressedAnimationProps {
  children: ReactNode;
}

const PressedAnimation: React.FC<PressedAnimationProps> = ({ children }) => {
  return <motion.div whileTap={{ scale: 0.95 }}>{children}</motion.div>;
};

export default PressedAnimation;
