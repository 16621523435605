import React, { ReactNode } from "react";
import styles from "./curvedFooter.module.scss";
import Footer from "../Footer";

interface CurvedFooterProps {
  children?: ReactNode;
}

const CurvedFooter: React.FC<CurvedFooterProps> = ({ children }) => {
  return (
    <div className={styles.curvedFooterContainer}>
      <div className={styles.curve}></div>
      {children}
      <Footer />
    </div>
  );
};

export default CurvedFooter;
