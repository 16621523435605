import Collapsible from "@components/animations/Collapsible";
import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import styles from "./collapseBox.module.scss";

interface CollapseBoxProps {
  title: string;
  children: React.ReactNode;
}

const CollapseBox = ({ title, children }: CollapseBoxProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={styles.question}
      data-testid="collapseBox"
      onClick={() => setOpen(!open)}
    >
      <div className={styles.titleQuestion}>
        <h6>{title}</h6>
        {open ? (
          <FaMinus data-testid="minusCollapseBox" />
        ) : (
          <FaPlus data-testid="plusCollapseBox" />
        )}
      </div>
      <Collapsible open={open}>
        <div className={styles.answer}>{children}</div>
      </Collapsible>
    </div>
  );
};

export default CollapseBox;
