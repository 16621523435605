import Cookies from "js-cookie";
import { useRouter } from "next/router";

export function useReferrer() {
  const router = useRouter();
  let { code } = router.query;
  if (code == undefined) {
    code = Cookies.get("referrer");
  }

  if (code) {
    Cookies.set("referrer", code!.toString(), { expires: 1 });
  }

  return {
    code: code as string,
  };
}
