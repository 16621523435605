import { ErrorMessage, Field, useField } from "formik";
import styles from "./rangeInput.module.scss";

interface RangeInputProps {
  className?: string;
  testId?: string;
  componentType?: string;
  isDisabled?: boolean;
  placeholder?: string;
  name: string;
  rangeLabel?: string[];
  min?: number;
  max?: number;
}

function RangeInput({
  componentType,
  name,
  rangeLabel,
  ...props
}: RangeInputProps & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element {
  const [field, meta] = useField(name);
  const style = {
    "--current": field.value,
    "--max": props.max,
    "--min": props.min,
  } as React.CSSProperties;

  return (
    <div className={styles.rangeInput}>
      <div
        className={meta.touched && meta.error ? styles.inputError : undefined}
      >
        <div className={styles.currentValue} style={style}>
          <h3>{field?.value}</h3>
        </div>
        <Field as="input" type="range" {...field} {...props} />
        {rangeLabel && (
          <div className={styles.rangeLabel}>
            <span>{rangeLabel[0]}</span>
            <span>{rangeLabel[1]}</span>
          </div>
        )}
      </div>
      <div className={styles.errorContainer}>
        <ErrorMessage className={styles.errorMsg} component="div" name={name} />
      </div>
    </div>
  );
}

export default RangeInput;
