import PressedAnimation from "@components/animations/PressedAnimation";
import Link from "next/link";
import React, { ReactNode } from "react";
import styles from "../button.module.scss";

interface ButtonLinkProps {
  themeColor:
    | "primary"
    | "primaryOutline"
    | "secondary"
    | "secondaryOutline"
    | "whiteSecondary"
    | "outlineGrey"
    | "google"
    | "apple"
    | "facebook"
    | "flatTextPrimary"
    | "flatTextSecondary"
    | "flatTextDanger";
  icon?: string;
  to: string;
  onClick?: () => void;
  children: ReactNode;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
  to,
  onClick,
  children,
  themeColor,
}) => {
  return (
    <PressedAnimation>
      <Link href={to}>
        <a
          data-testid="anchorTag"
          onClick={onClick}
          className={`${styles.button} ${styles[themeColor]}`}
        >
          {children}
        </a>
      </Link>
    </PressedAnimation>
  );
};
export default ButtonLink;
